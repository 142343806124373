import React from "react"
import { Stepper } from 'react-form-stepper'

import './PathwayWithSteps.css'

const PathwayWithSteps = (props) => {
  return (
      <Stepper
        steps={
          [{ label: 'Step 1: Information on eligibilty criteria' },
           { label: 'Step 2: Updating contact details' },
           { label: 'Step 3: Genuine and ongoing relationship' },
           { label: 'Step 4: Evidence of family violence' },
           { label: 'Step 5: What happens next' }]}
        activeStep={props.stepsInPathway}
        connectorStateColors={true}
        connectorStyleConfig={{
          completedColor: '#00882a',
          activeColor: '#00882a',
          disabledColor: '#cbd5e1'
        }}
        styleConfig={{
          activeBgColor: '#00a2c0',
          completedBgColor: '#00882a',
          inactiveBgColor: '#e2e8f0',
          activeTextColor: '#ffffff',
          completedTextColor: '#ffffff',
          inactiveTextColor: '#1e293b'
        }}
      />
  )
}

export default PathwayWithSteps;
