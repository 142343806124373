import React, {useContext} from "react"
import InterviewContext from "./InterviewContext"

import { ArrowRightCircleIcon } from '@heroicons/react/20/solid'
import "./QuickExitButton.css"

const QuickExitButton = (props) => {
  const { dispatch } = useContext(InterviewContext)
  return (
    <a href="#/"
    className='quick-exit z-20 text-white brandDanger hover:bg-brandDangerHover px-7 py-4 rounded-ButtonRadius shadow-md lg:font-medium text-md font-medium primary'
    onClick={(e) => {
        e.preventDefault()
        e.stopPropagation()
        dispatch({ type: 'UPDATE_NEXT_STATE', nextState: 'quickExit' })
      }}
    >
      <span>
        <ArrowRightCircleIcon
          className="inline h-6 w-6 mr-1 align-bottom" aria-hidden="true" />
      </span>
      {props.buttonText}
    </a>
   )
}

export default QuickExitButton;
