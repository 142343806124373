const translationsEn = {
  // ***************
  // * Framework *
  // ***************
  "exit-help-modal-txt": "Got it, thanks.",
  "exit-generic-modal-txt": "OK",
  "app-exit-message": "End of Pathway",
  "app-exit-thankyou": "<p class='mb-2'>You can use the pathway again if you need to find information for another client.</p>",
  "questions": "Navigation",
  "prev-question-txt": "Previous screen",
  "prev-question-explain": "Go back to the previous screen.",
  "quick-links": "Quick Links",
  "ql-mh-find": "Find mental health services",
  "ql-mh-find-explain": "Quickly find free or low-cost mental health services.",
  "ql-legal-find": "Find legal services",
  "ql-legal-find-explain": "Quickly find free or affordable legal services.",
  "ql-interpreter-find": "Find interpreting services",
  "ql-interpreter-find-explain": "Quickly find out when and how to ask for free interpreting.",
  "find-out-more-txt": "Find out more",
  "reload-txt": "Restart",
  "reload-explain": "Go through the guided pathway again from the start.",
  "main-menu-txt": "Main Menu",
  "main-menu-explain": "Go back to the main menu.",
  "da-main-menu": "Main Menu",
  "web-site-txt": "Go to main PCLC site",
  "about": "About",
  "about-app": "About Guide to Safety",
  "about-app-desc": "Find out more about this guided pathway.",
  "privacy-txt": "Privacy Collection Notice",
  "privacy-desc": "Get a better understanding of how we safeguard your privacy.",
  "website-terms": "Terms of Use",
  "website-terms-desc": "Read about the terms that apply when using our Guide to Safety.",
  "urgent-help": "Urgent Help",
  "restart-confirm-header":"Restart",
  "restart-confirm-text":"Are you sure you want to go through the guided pathway from the start?",
  "yes-answer": "Yes",
  "no-answer": "No",
  "OK-answer": "OK",
  "quick-exit": "Quick exit",
  "quick-exit-short": "Exit",
  "contact-info-txt": "Contact us",
  "contact-info-explain": "Contact us at the Peninsula Community Legal Centre",
  "contact-info-header": "Contact us",
  "continue-txt": "Continue",
  "previous-label": "Previous",
  "next-label": "Next",
  "phone": "Phone",
  "email": "Email",
  "website": "Website",
  "website-link": "Website",
  "address": "Address",
  "service-type": "Service type",
  "walkins-badge-label": "Walk-ins",
  "mht-label": "Mental Health Tribunal",
  "treatment-orders-label": "Treatment Orders",
  "family-law-label": "Family Law",
  "criminal-law-label": "Criminal Law",
  "immigration-law-label": "Immigration and asylum",
  "tenancy-law-label": "Tenancy",
  "guardianship_adm-law-label": "Guardianship and Administration",
  "ndis-label": "NDIS",
  "debts-label": "Debts",
  "vietnamese-label": "Services in Vietnamese and English",
  "walkins-available": "No appointment needed<span class=\"hidden md:inline\">, you can walk in</span>",
  "telehealth-badge-label": "Telehealth",
  "telehealth": "Phone or video<span class=\"hidden md:inline\"> (Telehealth)</span> appointments<span class=\"hidden md:inline\"> available</span>",

  // ***************
  // * Application *
  // ***************
  "alt-message-for-brand": "PCLC",
  "app-name-top-menu-item": "Guide to Safety",
  "menu-header": "Menu",
  "go-back-step": "Go Back a Step",
  "go-back-step-explain": "Go back a step in the Family Violence Provisions process.",

  // **************************
  // * Application Long Texts *
  // **************************
  // Urgent Help
  "urgent-help-text": `
    <p class="urgent-help"">
      Help available 24 hours x 7 days a week:
    </p>
    <p class="urgent-help">
      <b>Emergency, police and ambulance</b><br/>
      Call: <a href="tel:000">000</a><br/>
    </p>
    <p class="urgent-help">
      <b>1800 RESPECT</b> - Family violence and sexual assault help line<br/>
      Call: <a href="tel:1800737732">1800 737 732</a><br/>
      <a class="external-link" href="https://1800respect.org.au" target="_blank">
        1800respect.org.au
      </a>
    </p>
    <p class="urgent-help">
      <b>Safe Steps Family Violence Response Centre</b><br/>
      Call: <a href="tel:1800015088">1800 015 088</a><br/>
      <a class="external-link" href="https://safesteps.org.au" target="_blank">
        safesteps.org.au
      </a>
    </p>
    <p class="urgent-help pb-0">
      If you do not speak English, you can call these services and ask for an interpreter.
      The interpreter is free.
    </p>
    <p class="urgent-help pb-0">
      Or you can call the free <b>Translation and Interpreting Service (TIS)</b>
      on <a href="tel:131450">131&nbsp;450</a>:
      <ul class="text-brandSecondary ml-5" style="list-style-type:square;">
      <li><span class="text-brandTextDark">say your language</span></li>
      <li><span class="text-brandTextDark">ask to contact the service you need.</span></li>
      </ul>
    </p>`,

    // PCLC Contact Text
    "pclc-contact-text": `
    <p class="mt-4 xl:mt-8 mb-0 md:mb-4">
      Please contact us at the <b>Peninsula Community Legal Centre (PCLC)</b> by:-
    </p>
    <div class="table w-full">
      <div class="table-row-group">
        <div class="table-row md:pb-2 md:leading-7">
          <div class="block mt-2 md:table-cell md:w-5/12 lg:w-4/12">Phone:</div>
          <div class="block md:table-cell md:w-fit">
            <a href="tel:0397833600" class="phone-link">(03) 9783 3600</a>
          </div>
        </div>
        <div class="table-row md:pb-2 md:leading-7">
          <div class="block mt-2 md:table-cell md:w-5/12 lg:w-4/12">Toll-free:</div>
          <div class="block md:table-cell md:w-fit">
            <a href="tel:1800064784" class="phone-link">1800 064 784</a>
          </div>
        </div>
        <div class="table-row md:pb-2 md:leading-7">
          <div class="block mt-2 md:table-cell md:w-5/12 lg:w-4/12">Email:</div>
          <div class="block md:table-cell md:w-fit">
            <a href="mailto:migration@pclc.org.au" class="email-link" target="_blank">migration@pclc.org.au</a>
          </div>
        </div>
        <div class="table-row md:pb-2 md:leading-7">
          <div class="block mt-2 md:table-cell md:w-5/12 lg:w-4/12">Contact form:</div>
          <div class="block md:table-cell md:w-fit">
            <a href="https://pclc.org.au/contact-us/" target="_blank"
            class="form-link">pclc.org.au/contact-us</a>
          </div>
        </div>
        <div class="table-row md:pb-2 md:leading-7">
          <div class="block mt-2 md:table-cell md:w-5/12 lg:w-4/12">Website:</div>
          <div class="block md:table-cell md:w-fit">
            <a href="https://pclc.org.au/" class="web-link" target="_blank">pclc.org.au</a>
          </div>
        </div>

      </div>
    </div>`,

  // *******************************
  // * Mental Health Service Types *
  // *******************************
  // Mental Health and Wellbeing Local service
  "mh-and-wellbeing-local": `
    <p class="mb-2">
      <b>Mental Health and Wellbeing Local</b> are <b>free</b> services to help people
      with mental health challenges. They give free treatment, care and support.
    </p>
    <p class="mb-2">
      You do not need a doctor’s referral, you do not
      need a mental health care plan or a Medicare card to use the service.
    </p>
    <p class="mb-0">
      Family members, carers, friends or supporters can also get <b>free</b> help for
      themselves.
    </p>`,

    // Primary Health Network
    "phn": `
      <p class="mb-2">
        <b>Primary Health Networks (PHNs)</b> help people find mental health services
        close to them. They link different services, like hospitals, doctors,
        aged care facilities, and make sure people are getting the care they need.
        They also provide information and resources.
      </p>
      <p class="mb-0">
        There are 6 Primary Health Networks in Victoria each covering a different
        region.
      </p>`,

    // Mental Health and Wellbeing Hub
    "mh-and-wellbeing-hub": `
      <p class="mb-2">
        <b>Mental Health and Wellbeing Hubs</b> are free services that help
        people with mental health challenges. You don’t need a Medicare card
        or doctor referral. You can call to make an appointment or just walk in.
      </p>
      <p class="mb-0">
        Mental Health and Wellbeing Hubs are being replaced by Mental Health and
        Wellbeing Local services, but they continue to operate in areas where
        Local services are not available yet.
      </p>`,

    // headspace
    "headspace": `
      <p class="mb-2">
        <b>headspace</b> helps young people aged 12-25 with their mental health.
        They offer free online and phone help, as well as free or low-cost
        services.
      </p>
      <p class="mb-0">
        They also help family members and carers of people with mental health challenges.
      </p>`,

    // Family and Carer-Led Centre and Mental Health and Wellbeing Connect Centre
    "connect-centre": `
      <p class="mb-2">
        <b>Mental Health and Wellbeing Connect centres</b> are places where family
        members, carers and supporters of people with mental health challenges can go
        for support.
      </p>
      <p class="mb-0">
        They are free and anyone can visit and are run by people who have lived
        experience. They can give information, help find resources, and access
        to hardship money.
      </p>`,

    // Community Mental Health Clinic
    "community-mh-clinic": `
      <p class="mb-2">
        Saltwater Clinic is an adult community mental health clinic,
        providing mental health community care for people living in the
        Maribyrnong and Hobsons Bay municipalities. Saltwater Clinic is an
        appointment-only service.
      </p>
      <p class="mb-0">
        Referrals are through the Mercy Mental Health Triage.
      </p>`,

  // *********
  // * Pages *
  // *********
  // Privacy Policy
  "privacy-heading": "Privacy Collection Notice",
  "privacy-text": `
  <div class="static-conent">
    <h2 class="mt-2 font-sans text-lg text-brandSecondary font-medium">
      <span class="mr-2 md:mr-6"><b>1</b></span> Peninsula Community Legal Centre Inc. – <i>Guide to Safety</i> Privacy Collection Notice
    </h2>
    <p class="mb-4 mt-2">
      <span class="mr-1 md:mr-4"><b>1.1</b></span> Peninsula Community Legal Centre Inc. (PCLC) ABN 23 591 244 622 is an independent, not-for-profit and charitable institution established and operated in Australia.
    </p>
    <p class="mb-4 mt-2">
      <span class="mr-1 md:mr-4"><b>1.2</b></span> PCLC provides free legal services to people experiencing financial disadvantage, people with disabilities, those experiencing family violence, elder abuse,
      homelessness and other vulnerable groups across the South Eastern suburbs of Melbourne.
    </p>
    <p class="mb-4 mt-2">
      <span class="mr-1 md:mr-4"><b>1.3</b></span> PCLC issues the following notice regarding the collection of personal information via PCLC’s <i>Guide to Safety</i> web application.
    </p>

    <h2 class="mt-2 font-sans text-lg text-brandSecondary font-medium">
      <span class="mr-2 md:mr-6"><b>2</b></span> What personal information is collected?
    </h2>
    <p class="mb-4 mt-2">
      <span class="mr-1 md:mr-4"><b>2.1</b></span> We understand that privacy is of the utmost importance. For that reason, no personal information is collected about users of PCLC’s <i>Guide to Safety</i>.
      A person who accesses PCLC’s <i>Guide to Safety</i> will be anonymous.
    </p>
    <p class="mb-4 mt-2">
      <span class="mr-1 md:mr-4"><b>2.2</b></span> PCLC’s <i>Guide to Safety</i> uses session cookies to personalise the information that users receive based on how they answer questions in the web application.
      This data is kept only in session cookies in the web browser.
    </p>
    <p class="mb-4 mt-2">
      <span class="mr-1 md:mr-4"><b>2.3</b></span> PCLC uses Matomo Analytics cookies for research purposes to collect anonymised usage statistics such as the number users, the main menu items they select,
      and what pages or topics they view. Additionally, Matomo Analytics provides the general geographical location of the user's ISP data centre i.e. the specific state within Australia or overseas.
      This may be used to distinguish geographical areas where use of the web application has occurred.
    </p>
    <p class="mb-4 mt-2">
      <span class="mr-1 md:mr-4"><b>2.4</b></span> PCLC may collect personal information such as names, email addresses and other contact details from a person who contacts PCLC, either by completing
      an online form or calling us at our offices. However, PCLC provides the option for people to interact with us anonymously where general information is sought.
    </p>

    <h2 class="mt-2 font-sans text-lg text-brandSecondary font-medium">
      <span class="mr-2 md:mr-6"><b>3</b></span> Purpose
    </h2>
    <p class="mb-4 mt-2">
      <span class="mr-1 md:mr-4"><b>3.1</b></span> PCLC does not collect any personal information from users of PCLC’s <i>Guide to Safety</i>.
    </p>
    <p class="mb-4 mt-2">
      <span class="mr-1 md:mr-4"><b>3.2</b></span> PCLC may use aggregated or de-identified information it collects, in the following ways:
    </p>
    <ul class="text-brandTextDark">
      <li class="text-black mb-2" style="list-style-type: none;"><span class="mr-1 md:mr-4"><b>(a)</b></span> to expand website functionality in the future or to apply for further funding or resources; and</li>
      <li class="text-black mb-5" style="list-style-type: none;"><span class="mr-1 md:mr-4"><b>(b)</b></span> monitoring website traffic for reporting purposes, such as internal reporting and reporting to funders.</li>
    </ul>

    <h2 class="mt-2 font-sans text-lg text-brandSecondary font-medium">
      <span class="mr-2 md:mr-6"><b>4</b></span> Disclosure
    </h2>
    <p class="mb-4 mt-2">
      <span class="mr-1 md:mr-4"><b>4.1</b></span> PCLC recognises the importance of keeping personal information confidential and ensures that all information received from service users will not be disclosed to third
      parties without consent, unless authorised by law.
    </p>
    <p class="mb-4 mt-2">
      <span class="mr-1 md:mr-4"><b>4.2</b></span> PCLC ensures that:
    </p>
    <ul class="text-brandTextDark">
      <li class="text-black mb-2" style="list-style-type: none;"><span class="mr-1 md:mr-4"><b>4.2.1</b></span> No personal information will be disclosed to any third-party recipients, including overseas recipients.</li>
      <li class="text-black mb-5" style="list-style-type: none;"><span class="mr-1 md:mr-4"><b>4.2.2</b></span> No personal information will be sold to third parties or used for marketing purposes.</li>
    </ul>
    <p class="mb-4 mt-2">
      <span class="mr-1 md:mr-4"><b>4.3</b></span> Please refer to PCLC’s <a class='regularLink' href="https://pclc.org.au/wp-content/uploads/2014/05/PCLC_Client_Confidentiality___Privacy_Policy_Brochure.pdf" target="_blank">Client Confidentiality & Privacy Policy Brochure</a>
      for information about how client data is collected, how it will be used and the relevant confidentiality provisions.
    </p>

    <h2 class="mt-2 font-sans text-lg text-brandSecondary font-medium">
      <span class="mr-2 md:mr-6"><b>5</b></span> Other information
    </h2>
    <p class="mb-4 mt-2">
      <span class="mr-1 md:mr-4"><b>5.1</b></span> PCLC's
      <a class='regularLink' href="https://pclc.org.au/privacy/" target="_blank">
        Privacy Policy
      </a>
      outlines that Google Analytics is being utilised on the main PCLC website, that is the domain
      <a class='regularLink' href="https://pclc.org.au">pclc.org.au</a>
      and subdomain
      <a class='regularLink' href="https://www.pclc.org.au">www.pclc.org.au</a>.
    </p>
    <p class="mb-4 mt-2">
      <span class="mr-1 md:mr-4"><b>5.2</b></span> Google Analytics provides data on which pages are visited, for how long, on what operating systems and browsers, and from what geographical areas. Google Analytics will not identify a user of the site and will not
      obtain a person’s IP address.
    </p>
    <p class="mb-4 mt-2">
      <span class="mr-1 md:mr-4"><b>5.3</b></span> Google Analytics will <b>not</b> be used for the PCLC <i>Guide to Safety</i> web application.
    </p>

    <h2 class="mt-2 font-sans text-lg text-brandSecondary font-medium">
      <span class="mr-2 md:mr-6"><b>6</b></span> Contact
    </h2>
    <p class="mb-4 mt-2">
      <span class="mr-1 md:mr-4"><b>6.1</b></span> More information about how PCLC handles personal information is set out in PCLC’s
      <a class='regularLink' href="https://pclc.org.au/privacy/" target="_blank">
      Privacy Policy
      </a>.
    </p>
    <p class="mb-4 mt-2">
      <span class="mr-1 md:mr-4"><b>6.2</b></span> You may request a copy of PCLC’s Privacy Policy, access your personal information, seek correction of your personal information, or make a complaint about how your personal information has been handled by PCLC.
    </p>
    <p class="mb-4 mt-2">
      <span class="mr-1 md:mr-4"><b>6.3</b></span>
      If you have any questions about this notice or how we handle your personal information more generally, contact Peninsula Community Legal Centre:
      <a class='regularLink' href='mailto:pclc%40pclc.org.au'>pclc@pclc.org.au</a> or
      <a class='regularLink' href='tel:0397833600'>(03) 9783 3600</a>.
    </p>
  </div>`,

  // About Page
  "about-heading": "About Guide to Safety",
  "about-text": `
    <div class="static-conent">
    <p class="mb-2" style="margin-top: 0.5rem;">
      Peninsula Community Legal Centre (PCLC) acknowledges the traditional Aboriginal and Torres Strait Islander owners of country, recognises their continuing connection to land, water and community and
      pays respect to elders past, present, and future.
    </p>
    <h2 class="mt-4 font-sans text-lg text-brandSecondary font-medium">
      Credits
    </h2>
    <p class="mb-2" style="margin-top: 0.5rem;">
      Designed and built by Legal Tech Helper.
    </p>
    <p class="mb-2">
      Content by Peninsula Community Legal Centre and Legal Tech Helper.
    </p>
    <p class="mb-2">
      Legal review by Peninsula Community Legal Centre's Migration Law Clinic Team.
    </p>
    <p class="mb-2">
      Thank you to the organisations that participated in consultations and to
      our project team for their collaboration: Peninsula Community Legal Centre:
      Engy Abdelsalam, Kirsten Young; <br/>and Legal Tech Helper: Tatiana Lenz, Samantha Lovrich.
    </p>
    <p class="mb-2">
      We would like to specifically acknowledge Russell Kennedy for developing Guide to Safety’s Terms of Use and Privacy collection notice.
    </p>
    <p class="mb-2">
      We would also like to specifically acknowledge Victoria Legal Aid, E-Safety Commissioner, 1800RESPECT, and the Domestic and Family Violence
      Support Section at the Department of Home Affairs for the resources that have been used and linked to in the tool.
    </p>
    <p class="mb-4">
      Thank you to PCLC staff, Jackie Galloway, Sokha Um, Daniel Hombsch, and we extend our gratitude to all the lawyers, workers, specialist agencies and more that generously gave their time, input and feedback.
    </p>

    <h2 class="font-sans text-lg text-brandSecondary font-medium">
      Supported by
    </h2>
    <p class="mb-2" style="margin-top: 0.5rem;">
      Peninsula Community Legal Centre’s <i>Guide to Safety</i> is funded by a grant from the Victoria Law Foundation.
    </p>`,


  "about-version": `
      <h2 class="mt-5 font-sans text-lg text-brandSecondary font-medium">
        Version
      </h2>
      <p class="mb-4" style="margin-top: 0.25rem;">
        <i>Guide to Safety</i> - version 1.0.0
      </p>
      </div>`,

  // Website Terms Page
  "terms-heading": "Terms of Use",
  "terms-text": `
    <div class="static-conent">
      <h2 class="mt-2 font-sans text-lg text-brandSecondary font-medium">
        PCLC’s Guide to Safety Terms of Use
      </h2>
      <p class="mb-4 mt-2">
        Peninsula Community Legal Centre Inc (ABN 23 591 244 622) ("we", "us" or “PCLC”), is a not-for-profit and charitable institution established and operated in Australia.
      </p>
      <p class="mb-4 mt-2">
        Welcome to the PCLC Guide to Safety web application. This application is located on the web via the domain
        <a class='regularLink' href='https://pclc.legaltechhelper.com.au'>pclc.legaltechhelper.com.au</a> and includes all of the
        files located in that domain ("this site").
      </p>

      <h2 class="mt-2 font-sans text-lg text-brandSecondary font-medium">
        Agreement to these Website Terms of Use
      </h2>
      <p class="mb-4 mt-2">
        By accessing this site, you agree to be bound by these terms of use ("Website Terms of Use"). These Website Terms of Use constitute a binding agreement between you and PCLC
        and govern your use of this site.
      </p>

      <h2 class="mt-2 font-sans text-lg text-brandSecondary font-medium">
        Privacy Policy
      </h2>
      <p class="mb-4 mt-2">
        As part of these Website Terms of Use, your use of this site is also subject to our
        <a class='regularLink' href="https://pclc.org.au/privacy/" target="_blank">
          Privacy Policy
        </a>,
        which is incorporated by reference into these Website Terms of Use.
      </p>

      <h2 class="mt-2 font-sans text-lg text-brandSecondary font-medium">
        Restrictions on use
      </h2>
      <p class="mb-4 mt-2">
        <b>Prohibited conduct</b>
      </p>
      <p class="mb-4 mt-2">
        All users of our site are subject to the rules set out in Schedule 1 below, which are designed to protect PCLC and other users.
      </p>
      <p class="mb-2 mt-2">
        We reserve the right to, without notice:
      </p>
      <ul class="text-brandSecondary">
        <li class="mb-2 text-brandSecondary" style="list-style-type: disc;"><span class="text-black">suspend or block your access to this site or refuse to provide services to you if:</span></li>
      </ul>

      <ul class="dashed" style="padding-left: 0.75rem;">
        <li class="mb-2">
          you breach any provision of these Website Terms of Use; or
        </li>
        <li class="mb-2">
          the PCLC believes that your actions may cause damage and/or legal liability to the PCLC, or any other person; and
        </li>
      </ul>

      <ul class="text-brandSecondary">
        <li class="mb-2 text-brandSecondary" style="list-style-type: disc;">
          <span class="text-black">
          remove or block access to any information and/or materials that PCLC, at its sole and absolute discretion, regards in any way to be objectionable or in violation of any applicable law, any person's intellectual property rights or these Website Terms of Use.
          </span>
        </li>
      </ul>

      <h2 class="mt-6 font-sans text-lg text-brandSecondary font-medium">
        Intellectual property
      </h2>
      <p class="mb-2 mt-2">
        In these Website Terms of Use, the term "Proprietary Content" means:
      </p>
      <ul class="text-brandSecondary">
        <li class="mb-2 text-brandSecondary" style="list-style-type: disc;"><span class="text-black">this site;</span></li>
        <li class="mb-2 text-brandSecondary" style="list-style-type: disc;">
          <span class="text-black">
          all of its content (including all of the text, brands, trade marks, logos, images, graphics, designs, software, data, sound and video files and other information contained in this site, and the selection and arrangement thereof); and
          </span>
        </li>
        <li class="mb-2 text-brandSecondary" style="list-style-type: disc;">
          <span class="text-black">
            all software, systems and other information owned or used by the PCLC in connection with the services offered through this site (whether hosted on the same server as this site or otherwise).
          </span>
        </li>
      </ul>
      <p class="mb-4 mt-2">
        All Proprietary Content is the property of the PCLC or its licensors (as applicable) and is protected by Australian and international intellectual property laws. You must not reproduce, transmit,
        republish or prepare derivative works from any of the Proprietary Content, except as expressly authorised by these Website Terms of Use or with the prior written consent of the PCLC or other copyright owner (as applicable).
      </p>
      <p class="mb-4 mt-2">
        You may download and print out content from this site provided that you do not remove or modify any copyright, trade mark or other proprietary notices.
      </p>
      <p class="mb-4 mt-2">
        Nothing in these Terms constitutes a transfer of any Proprietary Content or intellectual property rights.
      </p>

      <h2 class="mt-6 font-sans text-lg text-brandSecondary font-medium">
        Privacy
      </h2>
      <p class="mb-2 mt-2">
        We value the privacy of our users and recognise their right to keep personal information or sensitive information private.
      </p>
      <p class="mb-2 mt-2">
        Non-identifiable information is collected from users of the Website for the purposes set out in our
        <a class='regularLink' href="./privacy">
        Privacy Collection Notice</a>.
        Any personal information collected from you will be
        used by us in accordance with the Privacy Collection Notice, as well as all relevant privacy laws and as described in our
        <a class='regularLink' href="https://pclc.org.au/privacy/" target="_blank">
        Privacy Policy</a>.
      </p>

      <h2 class="mt-6 font-sans text-lg text-brandSecondary font-medium">
        Disclaimer of warranties
      </h2>
      <p class="mb-2 mt-2">
        TO THE MAXIMUM EXTENT PERMITTED BY LAW, PCLC AND ITS OFFICERS, EMPLOYEES, AGENTS, CONSULTANTS, LICENSORS, PARTNERS AND AFFILIATES EXPRESSLY DISCLAIM ALL CONDITIONS, REPRESENTATIONS AND WARRANTIES (WHETHER EXPRESS OR IMPLIED,
        STATUTORY OR OTHERWISE) IN RELATION TO THIS SITE AND ANY SERVICES OBTAINED THROUGH THIS SITE.
      </p>
      <p class="mb-2 mt-2">
        This site is provided strictly on an "as is" basis. To the maximum extent permitted by law, PCLC and its officers, employees, agents, consultants, licensors, partners and affiliates make no representation, warranty or guarantee
        as to the reliability, timeliness, quality, suitability, truth, availability, accuracy or completeness of this site or any of its content, and in particular do not represent, warrant or guarantee that:
      </p>
      <ul class="text-brandSecondary">
        <li class="mb-2 text-brandSecondary" style="list-style-type: disc;"><span class="text-black">
          the use of this site will be secure, timely, uninterrupted or error-free or operate in combination with any other hardware, software, system or data;
        </span></li>
        <li class="mb-2 text-brandSecondary" style="list-style-type: disc;"><span class="text-black">
          this site will meet your requirements or expectations;
        </span></li>
        <li class="mb-2 text-brandSecondary" style="list-style-type: disc;"><span class="text-black">
          anything on this site, or on any third-party website referred or linked to in this site, is reliable, accurate, complete or up-to-date;
        </span></li>
        <li class="mb-2 text-brandSecondary" style="list-style-type: disc;"><span class="text-black">
          the quality of any services, information or other material obtained through this site will meet any particular requirements or expectations;
        </span></li>
        <li class="mb-2 text-brandSecondary" style="list-style-type: disc;"><span class="text-black">
          errors or defects will be corrected; or
        </span></li>
        <li class="mb-2 text-brandSecondary" style="list-style-type: disc;"><span class="text-black">
          this site or the servers that make it available are free of viruses or other harmful components.
        </span></li>
      </ul>

      <h2 class="mt-6 font-sans text-lg text-brandSecondary font-medium">
        Limitation of liability
      </h2>
      <p class="mb-4 mt-2">
        <b>Exclusion of liability</b>
      </p>
      <p class="mb-4 mt-2">
        To the maximum extent permitted by law, PCLC and its officers, employees, agents, consultants, licensors, partners and affiliates exclude all liability to you or any other person for any loss, cost, expense, claim or damage (whether
        arising in contract, negligence, tort, equity, statute or otherwise, and for any loss, whether it be consequential, indirect, incidental, special, punitive, exemplary or otherwise, including any loss of profits, loss or corruption
        of data or loss of goodwill) arising directly or indirectly out of, or in connection with, these Website Terms of Use or the use of this site by you or any other person.
      </p>
      <p class="mb-4 mt-2">
        <b>Remedies limited</b>
      </p>
      <p class="mb-2 mt-2">
        To the maximum extent permitted by law, the PCLC and its officers, employees, agents, consultants, licensors, partners and affiliates expressly limit their liability for breach of any non-excludable condition or warranty/guarantee
        implied by virtue of any legislation to the following remedies (the choice of which is to be at PCLC's sole discretion):
      </p>
      <ul class="dashed" style="padding-left: 0.75rem;">
        <li class="mb-2">
          the supply of the services again; or
        </li>
        <li class="mb-2">
          the payment of the cost of having the services supplied again.
        </li>
      </ul>

      <h2 class="mt-6 font-sans text-lg text-brandSecondary font-medium">
        General
      </h2>
      <p class="mb-4 mt-2">
        <b>Interpretation</b>
      </p>
      <p class="mb-2 mt-2">
        In these Website Terms of Use, the following rules of interpretation apply:
      </p>
      <ul class="text-brandSecondary">
        <li class="mb-2 text-brandSecondary" style="list-style-type: disc;"><span class="text-black">
          headings are for reference purposes only and in no way define, limit or describe the scope or extent of any provision in these Website Terms of Use;
        </span></li>
        <li class="mb-2 text-brandSecondary" style="list-style-type: disc;"><span class="text-black">
          these Website Terms of Use may not be construed adversely against PCLC solely because PCLC prepared them;
        </span></li>
        <li class="mb-2 text-brandSecondary" style="list-style-type: disc;"><span class="text-black">
          the singular includes the plural and vice-versa;
        </span></li>
        <li class="mb-2 text-brandSecondary" style="list-style-type: disc;"><span class="text-black">
          a reference to a "person" includes an individual, a firm, a corporation, a body corporate, a partnership, an unincorporated body, an association, a government body or any other entity; and
        </span></li>
        <li class="mb-2 text-brandSecondary" style="list-style-type: disc;"><span class="text-black">
          the meaning of general words is not limited by specific examples introduced by "including", "for example", "in particular" or similar expressions.
      </ul>
      <p class="mb-2 mt-2">
        <b>Assignment</b>
      </p>
      <p class="mb-4 mt-2">
        You may not assign, transfer or sub-contract any of your rights or obligations under these Website Terms of Use without PCLC's prior written consent.
      </p>
      <p class="mb-4 mt-2">
        PCLC may assign, transfer or sub-contract any of its rights or obligations under these Website Terms of Use at any time without notice to you.
      </p>
      <p class="mb-4 mt-2">
        <b>No waiver</b>
      </p>
      <p class="mb-4 mt-2">
        Waiver of any power or right under these Website Terms of Use must be in writing signed by the party entitled to the benefit of that power or right and is effective only to the extent
        set out in that written waiver. Any failure by PCLC to act with respect to a breach by you or others does not waive PCLC's right to act with respect to that breach or any subsequent
        or similar breaches.
      </p>
      <p class="mb-4 mt-2">
        <b>Severability</b>
      </p>
      <p class="mb-4 mt-2">
        The provisions of these Website Terms of Use are severable and, if any provision of these Website Terms of Use is held to be illegal, invalid or unenforceable under present or
        future law, such provision may be removed and the remaining provisions shall be enforced.
      </p>
      <p class="mb-4 mt-2">
        <b>Variation</b>
      </p>
      <p class="mb-4 mt-2">
        PCLC reserves the right to amend these Website Terms of Use and any other policy on this site at any time in its sole discretion and any such changes will, unless otherwise noted,
        be effective immediately. Your continued usage of this site will mean you accept those amendments. We reserve the right, without notice and at our sole discretion, to change, suspend,
        discontinue or impose limits on any aspect or content of this site or the products/services offered through this site.
      </p>
      <p class="mb-4 mt-2">
        <b>Governing law and jurisdiction</b>
      </p>
      <p class="mb-4 mt-2">
        These Website Terms of Use will be governed in all respects by the laws of Victoria. The parties irrevocably submit to the non-exclusive jurisdiction of the courts of Victoria and the courts of appeal from them.
      </p>


      <h2 class="mt-6 font-sans text-lg text-brandSecondary font-medium">
        Schedule 1 - Prohibited conduct
      </h2>
      <p class="mb-2 mt-2">
        YOU MUST NOT:
      </p>
      <ul class="text-brandSecondary">
        <li class="mb-2 text-brandSecondary" style="list-style-type: disc;"><span class="text-black">
          use any device, routine or software that interferes, or attempt to interfere, with the proper working of this site;
        </span></li>
        <li class="mb-2 text-brandSecondary" style="list-style-type: disc;"><span class="text-black">
          engage in any action that requires, or may require, an unreasonable or excessively large load on our infrastructure;
        </span></li>
        <li class="mb-2 text-brandSecondary" style="list-style-type: disc;"><span class="text-black">
          use this site to decipher passwords or security encryption codes, transmit any worms, viruses or Trojan horses, transfer or store illegal, threatening or obscene material or otherwise violate the security of any computer network;
        </span></li>
        <li class="mb-2 text-brandSecondary" style="list-style-type: disc;"><span class="text-black">
          use this site to violate any applicable local, state, national or international law, to engage in any misleading or deceptive online marketing practices or for any fraudulent or malicious purposes;
        </span></li>
        <li class="mb-2 text-brandSecondary" style="list-style-type: disc;"><span class="text-black">
          use any spider, robot or search/retrieval application or any screen scraping, data mining or similar data gathering device, process, program or means to access, retrieve or index any portion of this site;
        </span></li>
        <li class="mb-2 text-brandSecondary" style="list-style-type: disc;"><span class="text-black">
          use this site by any automated means;
        </span></li>
        <li class="mb-2 text-brandSecondary" style="list-style-type: disc;"><span class="text-black">
          use this site to transmit junk mail, spam or chain letters or pyramid schemes or engage in other flooding techniques or mass distribution of unsolicited email;
        </span></li>
        <li class="mb-2 text-brandSecondary" style="list-style-type: disc;"><span class="text-black">
          access, retrieve or index any portion of this site for use in constructing or populating any database that is searchable online or for the purpose of soliciting or sharing reviews;
        </span></li>
        <li class="mb-2 text-brandSecondary" style="list-style-type: disc;"><span class="text-black">
          interfere with the display of any advertisements appearing on or in connection with this site;
        </span></li>
        <li class="mb-2 text-brandSecondary" style="list-style-type: disc;"><span class="text-black">
          reverse engineer, decompile, disassemble, adapt, modify, translate, frame or reformat any of the material contained on this site;
        </span></li>
        <li class="mb-2 text-brandSecondary" style="list-style-type: disc;"><span class="text-black">
           reproduce, duplicate, copy or store any of the material appearing on this site other than as permitted under these Website Terms of Use.
        </span></li>
        <li class="mb-2 text-brandSecondary" style="list-style-type: disc;"><span class="text-black">
          falsely imply that any other website is associated with this site;
        </span></li>
        <li class="mb-2 text-brandSecondary" style="list-style-type: disc;"><span class="text-black">
          do anything that leads, or may lead, to a decrease in the value of PCLC's intellectual property rights in this site;
        </span></li>
        <li class="mb-2 text-brandSecondary" style="list-style-type: disc;"><span class="text-black">
          release to the public any news release, advertising material, promotional material or any other form of publicity or information relating to PCLC without PCLC's prior written consent; or
        </span></li>
        <li class="mb-2 text-brandSecondary" style="list-style-type: disc;"><span class="text-black">
          use this site to transmit any information or material that is, or may reasonably be considered to be:
        </span></li>
      </ul>

      <ul class="dashed" style="padding-left: 0.75rem;">
        <li class="mb-2">
          abusive, threatening, harassing, harmful, obscene, indecent, lewd, inflammatory, violent, vulgar, profane, racially, ethnically or otherwise objectionable or offensive in any way;
        </li>
        <li class="mb-2 text-brandSecondary" style="list-style-type: none;"><span class="text-black">
          libellous, defamatory, pornographic, sexually explicit, unlawful or plagiarised;
        </span></li>
        <li class="mb-2 text-brandSecondary" style="list-style-type: none;"><span class="text-black">
          infringing upon or violating any copyright, trademark, patent or other intellectual property or proprietary right;
        </span></li>
        <li class="mb-2 text-brandSecondary" style="list-style-type: none;"><span class="text-black">
          in breach of any duty of confidentiality by which you are bound, whether by way of a fiduciary or contractual relationship;
        </span></li>
        <li class="mb-2 text-brandSecondary" style="list-style-type: none;"><span class="text-black">
          in breach of any person’s right to privacy;
        </span></li>
        <li class="mb-2 text-brandSecondary" style="list-style-type: none;"><span class="text-black">
          a misrepresentation of facts, including the impersonation of any person or entity or a misrepresentation of an affiliation with any person or entity (including any sponsorship or endorsement);
        </span></li>
        <li class="mb-2 text-brandSecondary" style="list-style-type: none;"><span class="text-black">
          in violation of any applicable law, statute, ordinance or regulation, or encouraging of others to do so;
        </span></li>
        <li class="mb-2 text-brandSecondary" style="list-style-type: none;"><span class="text-black">
          containing any political campaigning material, advertisements or solicitations; or
        </span></li>
        <li class="mb-2 text-brandSecondary" style="list-style-type: none;"><span class="text-black">
          likely to bring the PCLC or any of its staff into disrepute.
        </span></li>
      </ul>
    </div>`,
}

export default translationsEn
