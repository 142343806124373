// From Remix Icon - "Simply Delightful Icon System"
// See https://remixicon.com and https://github.com/Remix-Design/remixicon
// released under the Apache 2.0 License
import * as React from "react";

function IconContactForDesktop(props) {
  return (
    <svg
      viewBox="0 0 24 24"
      fill="currentColor"
      strokeWidth="1.5"
      aria-hidden="true"
      {...props}
    >
      <path
         d="m 17.285658,15.200938 c 2.82173,-2.97819 2.773087,-7.6803293 -0.145711,-10.5991272 -2.968093,-2.9681683 -7.780472,-2.9681683 -10.7486403,0 C 3.4725088,7.5206087 3.4239636,12.222748 6.2456708,15.200938 7.3982387,13.366837 9.439584,12.147721 11.765655,12.147721 c 2.326069,0 4.367447,1.219116 5.520003,3.053217 z m -5.520003,8.594938 -6.9098713,-6.909805 c -3.8162181,-3.816304 -3.8162181,-10.0035654 0,-13.8197833 3.8162179,-3.816218 10.0034783,-3.816218 13.8197843,0 3.816196,3.8162179 3.816196,10.0034793 0,13.8197833 z m 0,-12.733937 c -1.7990325,0 -3.2573459,-1.4583132 -3.2573459,-3.2573444 0,-1.7989769 1.4583134,-3.2573444 3.2573459,-3.2573444 1.79903,0 3.257343,1.4583675 3.257343,3.2573444 0,1.7990312 -1.458313,3.2573444 -3.257343,3.2573444 z"
      />
    </svg>
  )
}

export default IconContactForDesktop
