import {Fragment, useRef, useContext, useState} from "react"
import { Outlet, useLocation } from "react-router-dom"
import { useTranslation } from 'react-i18next'
import { getCookie } from "./Utilities"
import { Disclosure, Menu, Popover, Transition } from '@headlessui/react'
import InterviewContext from "./InterviewContext"
import QuickExitButton from "./QuickExitButton"

import { ChevronDownIcon } from '@heroicons/react/20/solid'
// import { ChevronUpIcon } from '@heroicons/react/20/solid'
import LogoEnglish from "../images/logo-en.svg"
// import LogoVietnamese from "../images/logo-vi.svg"
// import PlainLogo from "../images/logo-minimal.svg"
import IconBack from "./icons/IconBack"
import IconReloadForMobileNav from "./icons/IconReloadForMobileNav"
import IconContact from "./icons/IconContact"
import IconContactForDesktop from "./icons/IconContactForDesktop"
import IconPath from "./icons/IconPath"
import IconUrgentHelp from "./icons/IconUrgentHelp"
import IconMainMenuForMobile from "./icons/IconMainMenuForMobile"
import IconBackAStepForMobile from "./icons/IconBackAStepForMobile"
// import IconQLMentalHealthForMobile from "./icons/IconQLMentalHealthForMobile"
// import IconQLLegalForMobile from "./icons/IconQLLegalForMobile"
// import IconQLInterpretForMobile from "./icons/IconQLInterpretForMobile"

import './TopMenu.css'
import './QuickExitButton.css'

import {
  Bars3CenterLeftIcon,
  NewspaperIcon,
  ShieldCheckIcon,
  StarIcon,
  XMarkIcon,
} from '@heroicons/react/24/outline'

// const mobileNavigationAbout = [
//   { name: 'about-app',
//     href: '/about',
//     icon: StarIcon,
//     lineBefore: false,
//     lineAfter: false,
//     current: false },
//
//   { name: 'privacy-txt',
//     href: '/privacy',
//     icon: ShieldCheckIcon,
//     lineBefore: false,
//     lineAfter: false,
//     current: false },

  // { name: 'website-terms',
  //   href: '/terms',
  //   icon: NewspaperIcon,
  //   lineBefore: false,
  //   lineAfter: false,
  //   current: false },
// ]

const aboutItems = [
  {
    name: 'about-app',
    description: 'about-app-desc',
    href: '/about',
    icon: StarIcon,
  },
  {
    name: 'privacy-txt',
    description: 'privacy-desc',
    href: '/privacy',
    icon: ShieldCheckIcon,
  },
  {
    name: 'website-terms',
    description: 'website-terms-desc',
    href: '/terms',
    icon: NewspaperIcon },
]

function IconBackForMobileNav() {
  return (
    <svg
      fill="currentColor"
      viewBox="0 0 24 24"
      strokeWidth="2.3"
      stroke="currentColor"
      aria-hidden="true"
      className="prev-question-on-mobile h-11 w-11 flex-shrink-0 text-brandPrimary"
    >
      <path strokeLinecap="round" strokeLinejoin="round" d="M10.5 19.5L3 12m0 0l7.5-7.5M3 12h18"></path>
    </svg>
  )
}

function IconBackForTopNav() {
  return (
    <svg
      fill="currentColor"
      viewBox="0 0 24 24"
      strokeWidth="2.3"
      stroke="currentColor"
      aria-hidden="true"
      className="dropdown-item-smaller h-10 w-10 flex-shrink-0 text-brandPrimary"
    >
      <path strokeLinecap="round" strokeLinejoin="round" d="M10.5 19.5L3 12m0 0l7.5-7.5M3 12h18"></path>
    </svg>
  )
}

function IconReloadForTopNav() {
  return (
    <svg
      fill="currentColor"
      viewBox="0 0 24 24"
      strokeWidth="0.75"
      stroke="currentColor"
      aria-hidden="true"
      className="dropdown-item-smaller h-10 w-10 flex-shrink-0 text-brandPrimary"
    >
      <path d="m3.9879 12.112c0-3.947 3.1642-7.8662 8.0893-7.8662 3.9774 0 5.9446 2.8625 6.7822 4.3856h-2.4869a0.71587 0.69613 0 0 0 0 1.3923h4.2952a0.71587 0.69613 0 0 0 0.71587-0.69613v-4.1768a0.71587 0.69613 0 0 0-1.4317 0v2.5242c-1.0079-1.7292-3.3417-4.8214-7.8746-4.8214-5.8172 0-9.5211 4.6432-9.5211 9.2585 0 4.6153 3.7039 9.2585 9.5211 9.2585 2.7833 0 5.0999-1.072 6.7492-2.7038a9.6929 9.4256 0 0 0 2.0445-3.017 0.71588 0.69614 0 1 0-1.3244-0.52906 8.2612 8.0333 0 0 1-1.741 2.5701c-1.3902 1.3783-3.3445 2.2875-5.7284 2.2875-4.9252 0-8.0893-3.9192-8.0893-7.8662z" />
    </svg>
  )
}

function IconMainMenu() {
  return (
    <svg
      fill="none"
      viewBox="0 0 24 24"
      strokeWidth="2"
      stroke="currentColor"
      aria-hidden="true"
      className="dropdown-item-smaller h-10 w-10 flex-shrink-0 text-brandPrimary"
    >
    <path d="M17.593 3.322c1.1.128 1.907 1.077 1.907 2.185V21L12 17.25 4.5 21V5.507c0-1.108.806-2.057 1.907-2.185a48.507 48.507 0 0111.186 0z" />
    </svg>
  )
}

function IconBackAStep() {
  return (
    <svg
      fill="currentColor"
      viewBox="0 0 24 24"
      strokeWidth="0.1"
      stroke="currentColor"
      aria-hidden="true"
      className="dropdown-item-smaller h-10 w-10 flex-shrink-0 text-brandPrimary"
    >
      <path d="M5.82843 6.99955L8.36396 9.53509L6.94975 10.9493L2 5.99955L6.94975 1.0498L8.36396 2.46402L5.82843 4.99955H13C17.4183 4.99955 21 8.58127 21 12.9996C21 17.4178 17.4183 20.9996 13 20.9996H4V18.9996H13C16.3137 18.9996 19 16.3133 19 12.9996C19 9.68584 16.3137 6.99955 13 6.99955H5.82843Z"></path>
    </svg>
  )
}

// function IconScalesOfJustice() {
//   return (
//     <svg
//       fill="none"
//       strokeLinecap="round"
//       strokeLinejoin="round"
//       viewBox="0 0 24 24"
//       strokeWidth="2"
//       stroke="currentColor"
//       aria-hidden="true"
//       className="dropdown-item-smaller h-10 w-10 flex-shrink-0 text-brandPrimary"
//
//     >
//       <path d="m12.028 1.3921v19.88c-1.7931 0-3.5106 0.3054-5.0978 0.86434m5.0978-0.86434c1.7931 0 3.5106 0.3054 5.0978 0.86434m3.1244-18.474a58.976 55.797 0 0 0-8.2222-0.54166c-2.7907 0-5.5363 0.18439-8.2222 0.54166m16.444 0c1.2303 0.1648 2.4484 0.36533 3.6543 0.59928m-3.6543-0.59928 3.1914 12.361c0.14861 0.57508-0.12912 1.1847-0.71746 1.3853a7.294 6.9009 0 0 1-2.474 0.40566 7.294 6.9009 0 0 1-2.474-0.40566c-0.58835-0.20053-0.86607-0.81018-0.71868-1.3853l3.1927-12.36zm-20.099 0.59928c1.2059-0.23395 2.424-0.43448 3.6543-0.59928m0 0 3.1914 12.361c0.14861 0.57508-0.12912 1.1847-0.71747 1.3853a7.2952 6.9021 0 0 1-2.474 0.40566 7.2952 6.9021 0 0 1-2.474-0.40566c-0.58835-0.20053-0.86607-0.81018-0.71868-1.3853l3.1927-12.36z"/>
//     </svg>
//   )
// }

// function IconSpeechBubble() {
//   return (
//     <svg
//       fill="none"
//       strokeLinecap="round"
//       strokeLinejoin="round"
//       viewBox="0 0 24 24"
//       strokeWidth="2"
//       stroke="currentColor"
//       aria-hidden="true"
//       className="dropdown-item-smaller h-10 w-10 flex-shrink-0 text-brandPrimary"
//     >
//       <path d="m21.754 7.5193c1.0452 0.33593 1.7735 1.3343 1.7735 2.4804v5.0697c0 1.3437-1.0015 2.484-2.3411 2.594-0.402 0.03194-0.804 0.06151-1.206 0.08517v3.6562l-3.5471-3.5486c-1.6009 0-3.1853-0.06506-4.7531-0.19281a2.5007 2.5017 0 0 1-0.97544-0.28625m11.049-9.8579a2.5137 2.5147 0 0 0-0.5628-0.11237 57.51 57.534 0 0 0-9.5156 0c-1.3372 0.11119-2.3363 1.2503-2.3363 2.5928v5.0697c0 0.99005 0.54388 1.8689 1.3656 2.3078m11.049-9.8579v-2.2167c0-1.9174-1.3621-3.5793-3.2633-3.8265a57.291 57.315 0 0 0-7.3779-0.47551c-2.5007 0-4.9635 0.16205-7.3779 0.47551-1.9012 0.24722-3.2633 1.9091-3.2633 3.8265v7.3645c0 1.9174 1.3621 3.5793 3.2633 3.8265 0.68222 0.08871 1.368 0.1656 2.0573 0.22947v5.5689l4.9127-4.9148"/>
//     </svg>
//   )
// }

// function IconSearch() {
//   return (
//     <svg
//       fill="none"
//       fillRule="evenodd"
//       clipRule="evenodd"
//       viewBox="0 0 24 24"
//       strokeWidth="2.3"
//       stroke="currentColor"
//       aria-hidden="true"
//       className="dropdown-item-smaller h-10 w-10 flex-shrink-0 text-brandPrimary"
//     >
//       <path d="m22.789 22.79-6.1886-6.1886m0 0a8.9314 8.9314 0 1 0-12.631-12.631 8.9314 8.9314 0 0 0 12.631 12.631z"/>
//     </svg>
//   )
// }

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export default function TopMenu() {
  const location = useLocation()
  const lang = getCookie("DA-language")
  // eslint-disable-next-line
  const { t, i18n } = useTranslation()
  const translate = i18n.getFixedT(lang)

  const buttonRef = useRef(null)
  // const buttonRef2 = useRef(null)
  const buttonRef3 = useRef(null)
  // const buttonRef4 = useRef(null)
  const mobileNavToggle = useRef(null)

  const { state, dispatch } = useContext(InterviewContext);

  const [useMobileBack, setUseMobileBack] = useState(true);

  let firstScreen = false
  try {
    firstScreen = (state.data.event_list[0] === process.env.REACT_APP_FIRST_SCREEN)? true: false
  } catch {  }

  // Find out if we have gone past the main meu
  let mainMenu = false
  try {
    mainMenu = (state.data.menu.items[0].anchor === "Main Menu")? true: false
  } catch {  }

  // Find out if we are sitting on the main meu
  let onMainMenu = false
  try {
    onMainMenu = (state.data.event_list[0] === "show_main_menu")? true: false
  } catch {  }

  // ... and if we are then which step in the Family Violence Provisions Process
  let stepsInPathway = "0"
  if (onMainMenu) {
    try {
      stepsInPathway = state.data.fields[0].choices[8].label
    } catch {  }
  }

  // show border after the Restart menu item on mobiles
  const showBorderBottom = ( (!mainMenu && !onMainMenu) || (onMainMenu && stepsInPathway === "0") )

  return (
    <>
    <Disclosure as="nav" className="sticky border-solid border-brandPrimary border-b-2 shadow-sm">
      {({ open }) => (
        <>
          <div className="mx-auto max-w-7xl px-2 sm:px-6 lg:px-8 xl:lg:px-4">
            <div className="relative flex h-16 items-center justify-between logo-container">

              {/* Logo */}
              <div className="flex flex-1 sm:items-stretch sm:justify-start">
                <div className="ml-2 md:ml-0 flex flex-shrink-0 items-center">
                  <a href="/"
                    onClick={(e) => {
                      if ( !firstScreen && location.pathname !== "/about" && location.pathname !== "/privacy" && location.pathname !== "/terms" ) {
                      e.preventDefault()
                      dispatch({ type: 'UPDATE_NEXT_STATE', nextState: 'restart' })
                      }

                      if ( firstScreen ) {
                        e.preventDefault()
                      }
                    } }
                  >
                      <img
                        className="w-auto xl:block logo-full"
                        src={LogoEnglish}
                        alt={translate("alt-message-for-brand")}
                      />
                  </a>
                </div>
              </div>

              <div className="absolute inset-y-0 right-1 flex items-center pr-1 sm:static sm:inset-auto sm:ml-6 sm:pr-0">

                {/* Mobile previous page button */}
                { location.pathname !== "/about" && location.pathname !== "/privacy" && location.pathname !== "/terms" &&
                  !firstScreen && useMobileBack &&

                  <a href="#/"
                    className="-m-3 flex items-start rounded-lg p-3 md:hidden" style={{marginRight: "2.5rem"}}
                    onClick={(e) => {
                      e.preventDefault()
                      buttonRef?.current?.click()
                      dispatch({ type: 'UPDATE_NEXT_STATE', nextState: 'goBack' })
                    }}
                  >
                    <IconBackForMobileNav className="dropdown-item-smaller h-12 w-12 flex-shrink-0 text-brandPrimary border-2 p-4 red" aria-hidden="true" />
                  </a>
                }

                {/* Mobile hamburger menu button */}
                <Menu as="div" className="relative ml-3">

                {/* Mobile menu button*/}
                <div className="absolute inset-y-0 right-0 flex items-center md:hidden">
                  <Disclosure.Button id="navigation-toggle" ref={mobileNavToggle}
                  className="inline-flex items-center justify-center rounded-md p-2 bg-brandPrimary text-white hover:bg-brandPrimaryHover"
                  onClick={(e) => {
                    setUseMobileBack(!useMobileBack)
                  }} >
                    <span className="sr-only">Open main menu</span>
                    {open ? (
                      <XMarkIcon className="block h-7 w-7" aria-hidden="true" />
                    ) : (
                      <Bars3CenterLeftIcon className="block h-7 w-7" aria-hidden="true" />
                    )}
                  </Disclosure.Button>
                </div>

                <div className="hidden sm:ml-6 md:block">
                  <div className="flex md:space-x-2 lg:space-x-4">


                    {/* Language Toggle */}
                    {/*
                    { lang !== "vi"  &&
                      <>
                      <a href="#/"
                        className="text-white bg-brandPrimary text-white hover:bg-brandPrimaryHover hover:text-white px-3 lg:px-4 py-4 md:py-3 lg:py-4 rounded-md text-md font-medium"
                        onClick={(e) => {
                          e.preventDefault()
                          document.cookie = "DA-language=vi; SameSite=Strict; Secure"
                          dispatch({ type: 'UPDATE_NEXT_STATE', nextState: 'setLanguageVi' })
                        }}
                      >
                        Tiếng Việt<span className="hidden xl:inline">&nbsp;| Vietnamese</span>
                      </a>
                      </>
                    }

                    { lang === "vi"  &&
                      <>
                      <a href="#/"
                        className="text-white bg-brandPrimary text-hite hover:bg-brandPrimaryHover hover:text-white px-3 lg:px-4 py-4 md:py-3 lg:py-4 rounded-md text-md font-medium"
                        onClick={(e) => {
                          e.preventDefault()
                          document.cookie = "DA-language=en; SameSite=Strict; Secure"
                          dispatch({ type: 'UPDATE_NEXT_STATE', nextState: 'setLanguageEn' })
                        }}
                      >
                        English<span className="hidden xl:inline">&nbsp;| Tiếng Anh</span>
                      </a>
                      </>
                    }
                    */}

                    {/*********************
                      * Desktop navigation *
                     ********************** */}

                    {/* Desktop: Navigation Menu Items - display only on "about" or "privacy" or "terms" pages */}
                    { (location.pathname === "/about" || location.pathname === "/privacy" || location.pathname === "/terms") &&
                      <a
                        href={document.location.origin}
                        className="ttext-brandPrimary group inline-flex items-center rounded-ButtonRadius bg-white text-base font-medium hover:bg-brandPrimaryHover hover:text-white focus:outline-none px-7 py-4"
                      >
                        { /*<QueueListIcon className="menu-icon inline h-5 w-5 mr-2 align-text-top flex-shrink-0 text-brandPrimary group-hover:text-white" aria-hidden="true" /> */}
                        {translate("app-name-top-menu-item")}
                      </a>
                    }

                    {/* Desktop: Questions/ Navigation dropdown - display when NOT on "about" or "privacy" or "terms" pages */}
                    { location.pathname !== "/about" && location.pathname !== "/privacy" && location.pathname !== "/terms" &&
                      firstScreen &&
                      <>
                      <div key="navigate" className="hidden lg:block px-3 lg:px-4 py-4 md:py-3 lg:py-4 text-slate-500">
                        {translate("questions")}
                      </div>
                      </>
                    }

                    {/* Questions/ Navigation  dropdown - display when NOT on "about" or "privacy" or "terms" pages */}
                    { location.pathname !== "/about" && location.pathname !== "/privacy" && location.pathname !== "/terms" &&
                      !firstScreen &&
                    <Popover className="relative">
                      {({ open }) => (
                        <>
                          <Popover.Button ref={buttonRef}
                            className={classNames(
                              open ? 'text-brandPrimary' : 'text-brandPrimary',
                              'group inline-flex items-center rounded-ButtonRadius bg-white text-base font-medium hover:bg-brandPrimaryHover hover:text-white focus:outline-none px-7 py-4'
                            )}
                          >
                            <span>{translate("questions")}</span>
                            <ChevronDownIcon
                              className={classNames(
                                open ? 'text-brandPrimary rotate-180' : 'text-brandPrimary',
                                'ml-2 h-5 w-5 group-hover:text-white'
                              )}
                              aria-hidden="true"
                            />
                          </Popover.Button>

                          <Transition
                            as={Fragment}
                            enter="transition ease-out duration-200"
                            enterFrom="opacity-0 translate-y-1"
                            enterTo="opacity-100 translate-y-0"
                            leave="transition ease-in duration-150"
                            leaveFrom="opacity-100 translate-y-0"
                            leaveTo="opacity-0 translate-y-1"
                          >
                            <Popover.Panel className="absolute right-0 z-10 -ml-2 mt-4 w-screen max-w-md">
                              <div className="overflow-hidden rounded-lg shadow-lg">
                                <div className="relative grid gap-6 bg-white px-5 py-6 sm:gap-8 sm:p-8">

                                  {/* Desktop: Previous screen*/}
                                  <a href="#/"
                                    className="-m-3 flex items-start rounded-lg p-3 hover:bg-gray-100"
                                    onClick={(e) => {
                                      e.preventDefault()
                                      buttonRef?.current?.click()
                                      dispatch({ type: 'UPDATE_NEXT_STATE', nextState: 'goBack' })
                                    }}
                                  >
                                    <IconBackForTopNav className="dropdown-item-smaller h-10 w-10 flex-shrink-0 text-brandPrimary" aria-hidden="true" />
                                    <div className="ml-4">
                                      <p className="text-md font-medium text-slate-900">
                                        {translate("prev-question-txt")}
                                      </p>
                                      <p className="mt-1 text-md text-gray-600">
                                        {translate("prev-question-explain")}
                                      </p>
                                    </div>
                                  </a>

                                  {/* Desktop: Restart*/}
                                  <a href="#/"
                                    className="-m-3 flex items-start rounded-lg p-3 hover:bg-gray-100"
                                    onClick={(e) => {
                                      e.preventDefault()
                                      buttonRef?.current?.click()
                                      dispatch({ type: 'UPDATE_NEXT_STATE', nextState: 'restart' })
                                    }}
                                  >
                                    <IconReloadForTopNav className="dropdown-item-smaller h-10 w-10 flex-shrink-0 text-brandPrimary" aria-hidden="true" />
                                    <div className="ml-4">
                                      <p className="text-md font-medium text-slate-900">
                                        {translate("reload-txt")}
                                      </p>
                                      <p className="mt-1 text-md text-gray-600">
                                        {translate("reload-explain")}
                                      </p>
                                    </div>
                                  </a>

                                  {/* Main menu */}
                                  { mainMenu  &&
                                    <a href="#/"
                                      className="-m-3 flex items-start rounded-lg p-3 hover:bg-gray-100"
                                      onClick={(e) => {
                                        e.preventDefault()
                                        buttonRef?.current?.click()
                                        dispatch({ type: 'UPDATE_NEXT_STATE', nextState: 'setBackToMainMenu' })
                                      }}
                                    >
                                      <IconMainMenu className="dropdown-item-smaller h-10 w-10 flex-shrink-0 text-brandPrimary" aria-hidden="true" />
                                      <div className="ml-4">
                                        <p className="text-md font-medium text-slate-900">
                                          {translate("main-menu-txt")}
                                        </p>
                                        <p className="mt-1 text-md text-gray-600">
                                          {translate("main-menu-explain")}
                                        </p>
                                      </div>
                                    </a>
                                  }

                                  {/* Go back a step */}
                                  { onMainMenu  && stepsInPathway !== "0" &&
                                   <a href="#/"
                                     className="-m-3 flex items-start rounded-lg p-3 hover:bg-gray-100"
                                     onClick={(e) => {
                                       e.preventDefault()
                                       buttonRef?.current?.click()
                                       dispatch({ type: 'UPDATE_NEXT_STATE', nextState: 'goBackStep' })
                                     }}
                                   >
                                     <IconBackAStep className="dropdown-item-smaller h-10 w-10 flex-shrink-0 text-brandPrimary" aria-hidden="true" />
                                     <div className="ml-4">
                                       <p className="text-md font-medium text-slate-900">
                                         {translate("go-back-step")}
                                       </p>
                                       <p className="mt-1 text-md text-gray-600">
                                         {translate("go-back-step-explain")}
                                       </p>
                                     </div>
                                   </a>
                                 }


                                  {/* Quick links under Navigation submenu  */}
                                  {/* Find mental health services */}
                                  {/*}
                                  <div className="block lg:hidden">
                                    <a href="#/"
                                      className="-m-3 flex items-start rounded-lg p-3 hover:bg-gray-100"
                                      onClick={(e) => {
                                        e.preventDefault()
                                        buttonRef?.current?.click()
                                        dispatch({ type: 'UPDATE_NEXT_STATE', nextState: 'qlMentalHealth' })
                                      }}
                                    >
                                      <IconSearch className="dropdown-item-smaller h-10 w-10 flex-shrink-0 text-brandPrimary" aria-hidden="true" />
                                      <div className="ml-4">
                                        <p className="text-md font-medium text-slate-900">
                                          {translate("ql-mh-find")}
                                        </p>
                                        <p className="mt-1 text-md text-gray-600">
                                          {translate("ql-mh-find-explain")}
                                        </p>
                                      </div>
                                    </a>
                                  </div>
                                  */}

                                  {/* Find legal services */}
                                  {/*}
                                  <div className="block lg:hidden">
                                    <a href="#/"
                                      className="-m-3 flex items-start rounded-lg p-3 hover:bg-gray-100"
                                      onClick={(e) => {
                                        e.preventDefault()
                                        buttonRef?.current?.click()
                                        dispatch({ type: 'UPDATE_NEXT_STATE', nextState: 'qlLegal' })
                                      }}
                                    >
                                      <IconScalesOfJustice className="dropdown-item-smaller h-10 w-10 flex-shrink-0 text-brandPrimary" aria-hidden="true" />
                                      <div className="ml-4">
                                        <p className="text-md font-medium text-slate-900">
                                          {translate("ql-legal-find")}
                                        </p>
                                        <p className="mt-1 text-md text-gray-600">
                                          {translate("ql-legal-find-explain")}
                                        </p>
                                      </div>
                                    </a>
                                  </div>
                                  */}
                                  {/* Find interpreting services */}
                                  {/*}
                                  <div className="block lg:hidden">
                                    <a href="#/"
                                      className="-m-3 flex items-start rounded-lg p-3 hover:bg-gray-100"
                                      onClick={(e) => {
                                        e.preventDefault()
                                        buttonRef?.current?.click()
                                        dispatch({ type: 'UPDATE_NEXT_STATE', nextState: 'qlInterpreting' })
                                      }}
                                    >
                                      <IconSpeechBubble className="dropdown-item-smaller h-10 w-10 flex-shrink-0 text-brandPrimary" aria-hidden="true" />
                                      <div className="ml-4">
                                        <p className="text-md font-medium text-slate-900">
                                          {translate("ql-interpreter-find")}
                                        </p>
                                        <p className="mt-1 text-md text-gray-600">
                                          {translate("ql-interpreter-find-explain")}
                                        </p>
                                      </div>
                                    </a>
                                  </div>
                                  */}

                                </div>
                              </div>
                            </Popover.Panel>
                          </Transition>
                        </>
                      )}
                    </Popover>
                    }

                    {/* Quick Links *
                    { location.pathname !== "/about" && location.pathname !== "/privacy" && location.pathname !== "/terms" &&
                    <Popover className="relative hidden lg:block">
                      {({ open2 }) => (
                        <>
                          <Popover.Button ref={buttonRef2}
                            className={classNames(
                              open2 ? 'text-brandPrimary' : 'text-brandPrimary',
                              'group inline-flex items-center rounded-ButtonRadius bg-white text-base font-medium hover:bg-brandPrimaryHover hover:text-white focus:outline-none px-7 py-4'
                            )}
                          >
                            <span>{translate("quick-links")}</span>
                            <ChevronDownIcon
                              className={classNames(
                                open2 ? 'text-brandPrimary rotate-180' : 'text-brandPrimary',
                                'ml-2 h-5 w-5 group-hover:text-white'
                              )}
                              aria-hidden="true"
                            />
                          </Popover.Button>

                          <Transition
                            as={Fragment}
                            enter="transition ease-out duration-200"
                            enterFrom="opacity-0 translate-y-1"
                            enterTo="opacity-100 translate-y-0"
                            leave="transition ease-in duration-150"
                            leaveFrom="opacity-100 translate-y-0"
                            leaveTo="opacity-0 translate-y-1"
                          >
                            <Popover.Panel className="absolute right-0 z-10 -ml-2 mt-4 w-screen max-w-md">
                              <div className="overflow-hidden rounded-lg shadow-lg ring-1 ring-black ring-opacity-5">
                                <div className="relative grid gap-6 bg-white px-5 py-6 sm:gap-8 sm:p-8">
                                  <a href="#/"
                                    className="-m-3 flex items-start rounded-lg p-3 hover:bg-gray-100"
                                    onClick={(e) => {
                                      e.preventDefault()
                                      buttonRef2?.current?.click()
                                      dispatch({ type: 'UPDATE_NEXT_STATE', nextState: 'qlMentalHealth' })
                                    }}
                                  >
                                    <IconSearch className="dropdown-item-smaller h-10 w-10 flex-shrink-0 text-brandPrimary" aria-hidden="true" />
                                    <div className="ml-4">
                                      <p className="text-md font-medium text-slate-900">
                                        {translate("ql-mh-find")}
                                      </p>
                                      <p className="mt-1 text-md text-gray-600">
                                        {translate("ql-mh-find-explain")}
                                      </p>
                                    </div>
                                  </a>
                                  <a href="#/"
                                    className="-m-3 flex items-start rounded-lg p-3 hover:bg-gray-100"
                                    onClick={(e) => {
                                      e.preventDefault()
                                      buttonRef2?.current?.click()
                                      dispatch({ type: 'UPDATE_NEXT_STATE', nextState: 'qlLegal' })
                                    }}
                                  >
                                    <IconScalesOfJustice className="dropdown-item-smaller h-10 w-10 flex-shrink-0 text-brandPrimary" aria-hidden="true" />
                                    <div className="ml-4">
                                      <p className="text-md font-medium text-slate-900">
                                        {translate("ql-legal-find")}
                                      </p>
                                      <p className="mt-1 text-md text-gray-600">
                                        {translate("ql-legal-find-explain")}
                                      </p>
                                    </div>
                                  </a>
                                  <a href="#/"
                                    className="-m-3 flex items-start rounded-lg p-3 hover:bg-gray-100"
                                    onClick={(e) => {
                                      e.preventDefault()
                                      buttonRef2?.current?.click()
                                      dispatch({ type: 'UPDATE_NEXT_STATE', nextState: 'qlInterpreting' })
                                    }}
                                  >
                                    <IconSpeechBubble className="dropdown-item-smaller h-10 w-10 flex-shrink-0 text-brandPrimary" aria-hidden="true" />
                                    <div className="ml-4">
                                      <p className="text-md font-medium text-slate-900">
                                        {translate("ql-interpreter-find")}
                                      </p>
                                      <p className="mt-1 text-md text-gray-600">
                                        {translate("ql-interpreter-find-explain")}
                                      </p>
                                    </div>
                                  </a>
                                </div>
                              </div>
                            </Popover.Panel>
                          </Transition>
                        </>
                      )}
                    </Popover>
                    }
                    */}

                    {/* About dropdown*/}
                    <Popover className="relative">
                      {({ open }) => (
                        <>
                          <Popover.Button ref={buttonRef3}
                            id="aboutButton"
                            className={classNames(
                              open ? 'text-brandPrimary' : 'text-brandPrimary',
                              'group inline-flex items-center rounded-ButtonRadius bg-white text-base font-medium hover:bg-brandPrimaryHover hover:text-white focus:outline-none px-7 py-4'
                            )}
                          >
                            <span>{translate("about")}</span>
                            <ChevronDownIcon
                              className={classNames(
                                open ? 'text-brandPrimary rotate-180' : 'text-brandPrimary',
                                'ml-2 h-5 w-5 group-hover:text-white'
                              )}
                              aria-hidden="true"
                            />
                          </Popover.Button>

                          <Transition
                            as={Fragment}
                            enter="transition ease-out duration-200"
                            enterFrom="opacity-0 translate-y-1"
                            enterTo="opacity-100 translate-y-0"
                            leave="transition ease-in duration-150"
                            leaveFrom="opacity-100 translate-y-0"
                            leaveTo="opacity-0 translate-y-1"
                          >
                            <Popover.Panel className="absolute right-0 z-10 -ml-2 mt-4 w-screen max-w-md">
                              <div className="overflow-hidden rounded-lg shadow-lg ring-1 ring-black ring-opacity-5">
                                <div className="relative grid gap-6 bg-white px-5 py-6 sm:gap-8 sm:p-8">
                                  {aboutItems.map((item, index) => (
                                    <a
                                      key={`desktop-about-${index}`}
                                      id={`desktop-about-${index}`}
                                      href={item.href}
                                      className="-m-3 flex items-start rounded-lg p-3 hover:bg-gray-100"
                                    >
                                      <item.icon className="dropdown-item h-10 w-10 flex-shrink-0 text-brandPrimary" aria-hidden="true" />
                                      <div className="ml-4">
                                        <p className="text-md font-medium text-slate-900">{translate(item.name)}</p>
                                        <p className="mt-1 text-md text-gray-600">{translate(item.description)}</p>
                                      </div>
                                    </a>
                                  ))}

                                  {/*}
                                  <div className="menu-items-separator lg:hidden"></div>
                                  */}
                                  {/* Contact us - tablet only */}
                                  <div className="block lg:hidden">
                                    <a href="#/"
                                      className="-m-3 flex items-start rounded-lg p-3 hover:bg-gray-100"
                                      onClick={(e) => {
                                        e.preventDefault()
                                        buttonRef?.current?.click()
                                        dispatch({ type: 'UPDATE_NEXT_STATE', nextState: 'showContactInfo' })
                                      }}
                                    >
                                      <IconContactForDesktop className="dropdown-item-smaller h-10 w-10 flex-shrink-0 text-brandPrimary" aria-hidden="true" />
                                      <div className="ml-4">
                                        <p className="text-md font-medium text-slate-900">
                                          {translate("contact-info-txt")}
                                        </p>
                                        <p className="mt-1 text-md text-gray-600">
                                          {translate("contact-info-explain")}
                                        </p>
                                      </div>
                                    </a>
                                  </div>


                                </div>
                              </div>
                            </Popover.Panel>
                          </Transition>
                        </>
                      )}
                    </Popover>

                    {/* Contact PCLC */}
                    <a href="#/"
                      onClick={(e) => {
                        e.preventDefault()
                        dispatch({ type: 'UPDATE_NEXT_STATE', nextState: 'showContactInfo' })
                      }}
                      className="text-white bg-brandSecondary hover:bg-brandSecondaryHover rounded-ButtonRadius bg-white text-base font-medium focus:outline-none px-7 py-4"
                    >
                      {translate("contact-info-txt")}
                    </a>

                    {/* Urgent Help */}
                    <a href="#/"
                      onClick={(e) => {
                        e.preventDefault()
                        dispatch({ type: 'UPDATE_NEXT_STATE', nextState: 'showUrgentHelp' })
                      }}
                      className="text-black bg-brandDanger hover:bg-brandDangerHover buttonWithOutline rounded-ButtonRadius text-md font-medium"
                    >
                      {translate("urgent-help")}
                    </a>
                    <QuickExitButton buttonText={translate("quick-exit")}/>
                  </div>
                </div>

                </Menu>
              </div>
            </div>
          </div>

          {/*********************
            * Mobile navigation *
           ********************** */}
          <Disclosure.Panel id="mobile-menu" className="absolute w-full absolute w-full h-screen bg-white md:hidden">
            <div className="space-y-1 px-2 border-t-1 border-white pb-3">

              {/* Mobile menu item:- Urgent Help */}
                <Disclosure.Button
                  key="urgent-help"
                  as="a"
                  href="#/"
                  onClick={(e) => {
                    e.preventDefault()
                    mobileNavToggle?.current?.click()
                    dispatch({ type: 'UPDATE_NEXT_STATE', nextState: 'showUrgentHelp' })
                  }}
                  className="text-white block px-3 py-6 w-100 text-md bg-brandSeaGreen mobile-menu-item-border-bottom"
                >
                  <IconUrgentHelp className="inline text-white h-7 w-7 mr-2 p-1 align-bottom" style={{position: "relative", left:"-3px"}} aria-hidden="true" />
                  <span className="text-white">
                    {translate("urgent-help")}
                  </span>
                </Disclosure.Button>

                {/* Mobile menu item:- Contact  */}
                {/*
                <Disclosure.Button
                  key="contact-for-mobile"
                  as="a"
                  href="#/"
                  onClick={(e) => {
                    e.preventDefault()
                    mobileNavToggle?.current?.click()
                    dispatch({ type: 'UPDATE_NEXT_STATE', nextState: 'showContactInfo' })
                  }}
                  className="text-white block px-3 py-6 w-100 text-md bg-brandSecondaryHover mobile-menu-item-border-bottom"
                >
                  <IconContact className="inline text-white h-7 w-7 mr-2 align-bottom" style={{padding: "0.1rem", position: "relative", left:"-4px", top: "-3px"}} aria-hidden="true" />
                  <span className="text-white">
                    {translate("contact-info-txt")}
                  </span>
                </Disclosure.Button>
                */}

              {/*
              { lang !== "vi"  &&

              <Disclosure.Button
                key="language-toggle"
                  as="a"
                  href="#/"
                  onClick={(e) => {
                    e.preventDefault()
                    mobileNavToggle?.current?.click()
                    dispatch({ type: 'UPDATE_NEXT_STATE', nextState: 'setLanguageVi' })
                  }}
                  className="text-white block px-3 py-6 w-100 text-md bg-brandMoreInfo mobile-menu-item-border-bottom"
                >
                  <div className="inline ml-2" aria-hidden="true">
                    Tiếng Việt&nbsp;| Vietnamese
                  </div>
                </Disclosure.Button>
              }

              { lang === "vi"  &&
              <Disclosure.Button
                key="language-toggle"
                  as="a"
                  href="#/"
                  onClick={(e) => {
                    e.preventDefault()
                    mobileNavToggle?.current?.click()
                    dispatch({ type: 'UPDATE_NEXT_STATE', nextState: 'setLanguageEn' })
                  }}
                  className="text-white block px-3 py-6 w-100 text-md bg-brandMoreInfo mobile-menu-item-border-bottom"
                >
                  <div className="inline ml-2" aria-hidden="true">
                    English&nbsp;| Tiếng Anh
                  </div>
                </Disclosure.Button>
              }
            */}

            {/* Link to Pathway Menu Item - display only on "about" or "privacy" or "terms" pages */}
            { (location.pathname === "/about" || location.pathname === "/privacy" || location.pathname === "/terms") &&
              <Disclosure.Button
                key="guided-pathway-mobile"
                as="a"
                href="/"
                className="text-brandPrimaryHover block px-6 py-6 m-0 w-100 text-md mobile-dropdown-panel mobile-menu-item-border-bottom"
              >
                <IconPath className="inline text-brandPrimaryHover h-6 w-5 mr-3 position: relative; left: -4px; top: -2px" aria-hidden="true" />
                <span className="text-brandPrimaryHover">
                  {translate("app-name-top-menu-item")}
                </span>
              </Disclosure.Button>
            }

            {/* Questions - display when NOT on "about" or "privacy" or "terms" pages */}
            { location.pathname !== "/about" && location.pathname !== "/privacy" && location.pathname !== "/terms" &&
              !firstScreen &&
            <>

            {/* Mobile menu item:- Previous screen */}
              <Disclosure.Button
                key="previous"
                href="#/"
                onClick={(e) => {
                  e.preventDefault()
                  mobileNavToggle?.current?.click()
                  dispatch({ type: 'UPDATE_NEXT_STATE', nextState: 'goBack' })
                }}
                className="text-brandPrimaryHover block px-3 pt-4 pb-5 m-0 w-100 text-md"
              >
                <IconBack className="inline text-brandPrimaryHover h-7 w-7 mr-2 p-1 align-bottom" style={{position: "relative", left: "-1px"}} aria-hidden="true" />
                <span className="brandPrimaryHover">{translate("prev-question-txt")}</span>
              </Disclosure.Button>

              {/* Mobile menu item:- Restart */}
              <Disclosure.Button
                key="restart"
                href="#/"
                onClick={(e) => {
                  e.preventDefault()
                  mobileNavToggle?.current?.click()
                  dispatch({ type: 'UPDATE_NEXT_STATE', nextState: 'restart' })
                }}

                className={`text-brandPrimaryHover block px-3 pb-6 mb-0 w-100 text-md ${!showBorderBottom ? "" : "pl-5 pb-6 mb-0 mobile-menu-item-border-bottom mobile-menu-item-correction"} `}
              >
                <IconReloadForMobileNav className="inline text-brandPrimaryHover h-7 w-7 mr-2 p-1 align-bottom" style={{position: "relative", top: "1px", left: "-1px"}} aria-hidden="true" />
                <span className="text-brandPrimaryHover">{translate("reload-txt")}</span>
              </Disclosure.Button>

              {/* Mobile menu item:- Go back a step */}
              { onMainMenu  && stepsInPathway !== "0" &&
                <Disclosure.Button
                  key="go_back"
                  href="#/"
                  onClick={(e) => {
                    e.preventDefault()
                    mobileNavToggle?.current?.click()
                    dispatch({ type: 'UPDATE_NEXT_STATE', nextState: 'goBackStep' })
                  }}
                  className={`text-brandPrimaryHover block px-3 pb-6 mb-0 w-100 text-md ${showBorderBottom ? "" : "pb-6 mb-0 mobile-menu-item-border-bottom"} `}
                >
                  <IconBackAStepForMobile className="inline text-brandPrimaryHover h-7 w-7 mr-1 p-1 align-bottom" style={{position: "relative", top: "1px", left: "-5px"}} aria-hidden="true" />
                  <span className="text-brandPrimaryHover">{translate("go-back-step")}</span>
                </Disclosure.Button>
              }

              {/* Mobile menu item:- Main Menu */}
              { mainMenu  &&
                <Disclosure.Button
                  key="main-menu-mobile"
                  href="#/"
                  onClick={(e) => {
                    e.preventDefault()
                    mobileNavToggle?.current?.click()
                    dispatch({ type: 'UPDATE_NEXT_STATE', nextState: 'setBackToMainMenu' })
                  }}
                  className={`text-brandPrimaryHover block px-3 pb-6 mb-0 w-100 text-md pb-6 mb-0 mobile-menu-item-border-bottom`}
                >
                  <IconMainMenuForMobile className="inline text-brandPrimaryHover h-7 w-7 mr-2 p-1 align-bottom" style={{position: "relative", top: "1px", left: "-5px", marginRight: "4px"}} aria-hidden="true" />
                  <span className="text-brandPrimaryHover">{translate("main-menu-txt")}</span>
                </Disclosure.Button>
              }
            </>
            }

            {/* Mobile Menu: Quick links*/}
            {/*
            { (location.pathname !== "/about" && location.pathname !== "/privacy" && location.pathname !== "/terms") &&
            <Disclosure key="quick-links">
              {({ open }) => (
                <>
                  <Disclosure.Button className="flex w-full justify-between bg-brandPrimaryHover px-6 py-5 text-left font-medium text-white text-md text-md mobile-dropdown-panel mobile-menu-item-border-bottom">
                    <span>{translate("quick-links")}</span>
                    <ChevronDownIcon
                      className={`${
                        open ? 'rotate-180 transform' : ''
                      } h-7 w-7`}
                    />
                  </Disclosure.Button>
                  <Disclosure.Panel>
              */}
                    {/* Mobile Menu: Find mental health services*/}
              {/*
                    <Disclosure.Button
                      key="ql-mh-find"
                      href="#/"
                      onClick={(e) => {
                        e.preventDefault()
                        mobileNavToggle?.current?.click()
                        dispatch({ type: 'UPDATE_NEXT_STATE', nextState: 'qlMentalHealth' })
                      }}
                      className="text-brandPrimaryHover block px-3 pt-5 pb-6 w-100 text-md"
                    >
                      <IconQLMentalHealthForMobile className="inline text-brandPrimaryHover h-7 w-7 mr-2 p-1 align-bottom" aria-hidden="true" />
                      <span className="text-brandPrimaryHover">{translate("ql-mh-find")}</span>
                    </Disclosure.Button>
              */}

                    {/* Mobile Menu: Find legal services*/}
              {/*}
                    <Disclosure.Button
                      key="ql-legal-find"
                      href="#/"
                      onClick={(e) => {
                        e.preventDefault()
                        mobileNavToggle?.current?.click()
                        dispatch({ type: 'UPDATE_NEXT_STATE', nextState: 'qlLegal' })
                      }}
                      className="text-brandPrimaryHover block px-3 pb-6 w-100 text-md"
                    >
                      <IconQLLegalForMobile className="inline text-brandPrimaryHover h-7 w-7 mr-2 p-1 align-bottom" aria-hidden="true" />
                      <span className="text-brandPrimaryHover">{translate("ql-legal-find")}</span>
                    </Disclosure.Button>
                */}

                    {/* Mobile Menu: Find interpreting services*/}
                {/*
                    <Disclosure.Button
                      key="ql-interpreter-find"
                      href="#/"
                      onClick={(e) => {
                        e.preventDefault()
                        mobileNavToggle?.current?.click()
                        dispatch({ type: 'UPDATE_NEXT_STATE', nextState: 'qlInterpreting' })
                      }}
                      className="text-brandPrimaryHover block px-3 pb-6 mb-4 w-100 text-md mobile-menu-item-border-bottom"
                    >
                      <IconQLInterpretForMobile className="inline text-brandPrimaryHover h-7 w-7 mr-2 p-1 align-bottom" style={{position: "relative", left: "-3px"}} aria-hidden="true" />
                      <span className="text-brandPrimaryHover">{translate("ql-interpreter-find")}</span>
                    </Disclosure.Button>

                  </Disclosure.Panel>
                </>
              )}
            </Disclosure>
            }
            */}

              {/* Mobile Menu : About links*/}
              <Disclosure key="about-links">
                {({ open }) => (
                  <>
                    <Disclosure.Button key="about-links-chevron"
                      className="flex w-full justify-between bg-white px-6 py-5 text-left font-medium text-brandPrimaryHover text-md text-md mobile-dropdown-panel mobile-menu-item-border-bottom"
                    >
                      <span>{translate("about")}</span>
                      <ChevronDownIcon
                        className={`${
                          open ? 'rotate-180 transform' : ''
                        } h-7 w-7`}
                    />
                    </Disclosure.Button>

                    <Disclosure.Panel
                      className="m-0"
                    >

                    {/*
                    {mobileNavigationAbout.map((item, index) => (
                      <Disclosure.Button key={`mobile-about-${index}`}
                        id={`mobile-about-${index}`}
                        as="a"
                        href={item.href}
                        className= {`${
                          (index === 0) ? 'pt-5' : ''
                        } text-brandPrimaryHover block px-3 pb-6 m-0 w-100 text-md`}
                      >
                        <span id={item.name}>
                        <item.icon className="inline text-brandPrimaryHover h-7 w-7 mr-2 p-1 align-bottom" style={{padding: "0.1rem!important"}} aria-hidden="true" />
                        </span>
                        <span className="text-brandPrimaryHover">{translate(item.name)}</span>
                      </Disclosure.Button>
                    ))}
                    */}

                    <Disclosure.Button key="mobile-about-app"
                      id="mobile-about-app"
                      as="a"
                      href="./about"
                      className= "pt-5 text-brandPrimaryHover block px-3 pb-6 m-0 w-100 text-md"
                    >
                      <span id="about-app-01">
                      <StarIcon className="inline text-brandPrimaryHover h-7 w-6 mr-3 align-bottom" style={{padding: "0.1rem!important"}} aria-hidden="true" />
                      </span>
                      <span className="text-brandPrimaryHover">{translate('about-app')}</span>
                    </Disclosure.Button>

                    <Disclosure.Button key="mobile-privacy"
                      id="mobile-privacy"
                      as="a"
                      href="./privacy"
                      className= "text-brandPrimaryHover block px-3 pb-6 m-0 w-100 text-md"
                    >
                      <span id="about-app-02">
                      <ShieldCheckIcon className="inline text-brandPrimaryHover h-7 w-6 mr-3 align-bottom" style={{padding: "0.1rem!important"}} aria-hidden="true" />
                      </span>
                      <span className="text-brandPrimaryHover">{translate('privacy-txt')}</span>
                    </Disclosure.Button>

                    <Disclosure.Button key="website-terms"
                      id="website-terms"
                      as="a"
                      href="./terms"
                      className= "text-brandPrimaryHover block px-3 pb-8 m-0 w-100 text-md"
                    >
                      <span id="about-app-03">
                      <NewspaperIcon className="inline text-brandPrimaryHover h-7 w-6 mr-3 align-bottom" style={{padding: "0.1rem!important"}} aria-hidden="true" />
                      </span>
                      <span className="text-brandPrimaryHover">{translate('website-terms')}</span>
                    </Disclosure.Button>


                  </Disclosure.Panel>
                  </>
                )}

              </Disclosure>

              {/* Mobile menu item:- Contact  */}
              <Disclosure.Button
                key="contact-for-mobile"
                as="a"
                href="#/"
                onClick={(e) => {
                  e.preventDefault()
                  mobileNavToggle?.current?.click()
                  dispatch({ type: 'UPDATE_NEXT_STATE', nextState: 'showContactInfo' })
                }}
                className="text-white block px-3 py-6 w-100 text-md bg-brandSecondaryHover mobile-menu-item-border-bottom"
              >
                <IconContact className="inline text-white h-7 w-7 mr-2 align-bottom" style={{padding: "0.1rem", position: "relative", left:"-4px", top: "-3px"}} aria-hidden="true" />
                <span className="text-white">
                  {translate("contact-info-txt")}
                </span>
              </Disclosure.Button>

            </div>
          </Disclosure.Panel>

          <div className="md:hidden">
            <QuickExitButton
              lang = {lang}
              buttonText={translate("quick-exit-short")}
            />
          </div>
        </>
      )}

    </Disclosure>

    <Outlet />
  </>
  )
}
