import {React} from "react"
import { useTranslation } from 'react-i18next'
import {getCookie} from "./Utilities"
import SimpleTextPage from "./SimpleTextPage"
import VLFLogo from "../images/VLF-logo.svg"
import AboriginalFlag from "../images/aboriginal-flag.svg"
import TorresStraitFlag from "../images/torres-strait-flag.svg"

const About = () => {
  const lang = getCookie("DA-language")
  // eslint-disable-next-line
  const { t, i18n } = useTranslation()
  const translate = i18n.getFixedT(lang)

  // eslint-disable-next-line
  const mainText = '<img class="mt-3 md:mt-2 mr-4" style="display: inline; max-height: 2.5rem;" src=' + `${AboriginalFlag}` + ' alt="Aboriginal flag" />' +
  // eslint-disable-next-line
  '<img class="mt-3 md:mt-2" style="display: inline; max-height: 2.5rem;" src=' + `${TorresStraitFlag}` + ' alt="Torres Strait Islander flag" />' +
  // eslint-disable-next-line
  translate('about-text') + '<img className="w-auto xl:block logo-full" style="max-width: 168px;" src=' +
  // eslint-disable-next-line
  `${VLFLogo}` + ' alt="Victoria Law Foundation"/>' + translate('about-version')


  return (
    <>

      <div>
        <div id="interview-background"></div>
        <SimpleTextPage heading={translate('about-heading')} mainText= {mainText}/>
      </div>
    </>
    )
}

export default About
