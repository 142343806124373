import React from "react"
import Interview from "./Interview"
import InterviewWithImage from "./pages/special-screens/InterviewWithImage"
import InterviewWithVideo from "./pages/special-screens/InterviewWithVideo"
import BackLink from "./BackLink"
import { specialPages } from "./Customization"

import './InterviewWrappper.css'

const InterviewWrapper = (props) => {
    // Check if using custom, "special" screen/ page
    let screenType = "regular"
    const specialPage = specialPages.find(({ screen }) => screen === props.interview.fields[0].variable_name)
    if (typeof(specialPage) !== "undefined") {
      screenType = specialPage.extraScreen
    }

    // Menu screens
    if (screenType === "main-menu" || screenType === "menu-by-two" ||
        screenType === "menu-by-two-long" || screenType === "main-menu") {
      return (
        <>
          <div id="interview-background"></div>
          <div id="interview" className="flex">
            <div className="m-auto">
              <div id="cards-outer-container">

                {/* Back Button */}
                {props.interview.event_list && props.interview.event_list.length > 0 && props.interview.event_list[0] !== process.env.REACT_APP_FIRST_SCREEN &&
                <div className="hidden md:block my-4">
                  <BackLink goPrevQuestion = {props.goPrevQuestion} />
                </div>
                }
                  <div id="interview-outer-container"
                  className="px-4 mx-0 md:p-6 md:mx-auto lg:p-10 md:rounded-md md:bg-white md:shadow-2xl md:w-screen lg:max-w-screen-lg xl:max-w-screen-xl">

                  <Interview
                    isLoadedInApp={props.isLoadedInApp}
                    showButtonSpinner={props.showButtonSpinner}
                    interview={props.interview}
                    sessionID={props.sessionID}
                    handleClick={props.handleClick}
                    goPrevQuestion = {props.goPrevQuestion}
                    restartInterview = {props.restartInterview}
                    screenType = {screenType}
                  />

                </div>
              </div>
            </div>
          </div>
        </>
      )
    }

    // Resultset screen and splash screen where the rendering is fully defined
    if (screenType === "with-image") {
      return (
        <>
          <InterviewWithImage
            isLoadedInApp={props.isLoadedInApp}
            showButtonSpinner={props.showButtonSpinner}
            interview={props.interview}
            sessionID={props.sessionID}
            handleClick={props.handleClick}
            goPrevQuestion = {props.goPrevQuestion}
            restartInterview = {props.restartInterview}
            screenType = {screenType}
          />
        </>
      )
    }

    if (screenType === "with-video") {
      return (
        <>
          <InterviewWithVideo
            isLoadedInApp={props.isLoadedInApp}
            showButtonSpinner={props.showButtonSpinner}
            interview={props.interview}
            sessionID={props.sessionID}
            handleClick={props.handleClick}
            goPrevQuestion = {props.goPrevQuestion}
            restartInterview = {props.restartInterview}
            screenType = {screenType}
          />
        </>
      )
    }
    if (screenType === "resultset" || screenType === "resultset-legal" || screenType === "splash-screen") {
      return (
        <>
          <Interview
            isLoadedInApp={props.isLoadedInApp}
            showButtonSpinner={props.showButtonSpinner}
            interview={props.interview}
            sessionID={props.sessionID}
            handleClick={props.handleClick}
            goPrevQuestion = {props.goPrevQuestion}
            restartInterview = {props.restartInterview}
            screenType = {screenType}
          />
        </>
      )
    }

    // Default rendering, including regular interview
    return (
      <>
        <div id="interview-background"></div>
        <div id="interview" className="flex">
          <div className="m-auto">

            {/* Back Button */}
            {props.interview.event_list && props.interview.event_list.length > 0 && props.interview.event_list[0] !== process.env.REACT_APP_FIRST_SCREEN &&
            <div className="back-link hidden md:my-4 sm:mx-0 md:block min-w-screen">
              <BackLink goPrevQuestion = {props.goPrevQuestion} />
            </div>
            }

            {/* Back Button */}
            {props.interview.event_list && props.interview.event_list.length > 0 && props.interview.event_list[0] === process.env.REACT_APP_FIRST_SCREEN &&
            <div className="back-link hidden md:my-14 xl:mx-0 md:block min-w-screen">
            </div>
            }

            {/* Width dependant on screen type*/}
            <div id="interview-outer-container"
            className="px-4 mx-0 md:p-6 md:mx-auto lg:p-10 md:rounded-md md:bg-white md:shadow-2xl md:w-screen lg:max-w-screen-lg xl:max-w-screen-xl">

            <Interview
              isLoadedInApp={props.isLoadedInApp}
              showButtonSpinner={props.showButtonSpinner}
              interview={props.interview}
              sessionID={props.sessionID}
              handleClick={props.handleClick}
              goPrevQuestion = {props.goPrevQuestion}
              restartInterview = {props.restartInterview}
              screenType = {screenType}
            />

            </div>
          </div>
        </div>
      </>
    )
}

export default InterviewWrapper;
