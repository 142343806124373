const specialPages = [
  // { screen: "show_first_screen", extraScreen: "splash-screen"},
  { screen: "show_interpreter_submenu", extraScreen: "menu-by-two"},
  { screen: "show_services_01", extraScreen: "with-image"},
  { screen: "legal_services_intro", extraScreen: "with-image"},
  { screen: "mental_health_intro_01", extraScreen: "with-image"},
  { screen: "show_interpreter_01", extraScreen: "with-image"},
  // { screen: "ending_screen", extraScreen: "with-image"},
  { screen: "show_gen_court_dn_00", extraScreen: "with-image"},
  { screen: "show_fv_help_04", extraScreen: "with-video"},
  { screen: "show_fv_help_05", extraScreen: "with-video"},
  { screen: "show_fvio_03", extraScreen: "with-video"},
  { screen: "show_other_resources_04", extraScreen: "with-video"},

  // { screen: "AVWA_contact_point", extraScreen: "menu-by-two"},
  { screen: "AVWA_contact_point", extraScreen: "radio-buttons-choice"},
  // { screen: "show_court_choice", extraScreen: "menu-by-two-long"},
  { screen: "worker_type_choice", extraScreen: "radio-buttons-choice"},
  { screen: "ask_visa_type", extraScreen: "radio-buttons-choice"},
  { screen: "ask_correspondence_type", extraScreen: "radio-buttons-choice"},

  { screen: "show_court_choice", extraScreen: "radio-buttons-choice"},
  { screen: "AVWA_contact_point", extraScreen: "menu-by-two"},
  { screen: "menu_cards", extraScreen: "card-screen"},
  { screen: "mental_health_search", extraScreen: "fill-in-the-blanks"},
  { screen: "legal_services_search", extraScreen: "fill-in-the-blanks"},
  { screen: "mental_health_resultset_page", extraScreen: "resultset"},
  { screen: "ls_resultset_page", extraScreen: "resultset-legal"},
  { screen: "show_main_menu", extraScreen: "main-menu"},
  { screen: "mental_health_submenu", extraScreen: "menu-by-two"},
  { screen: "legal_services_submenu", extraScreen: "menu-by-two"},
  { screen: "show_legal_area_choice", extraScreen: "menu-by-two"},
  { screen: "mh_hospitals", extraScreen: "with-urgent-help"},

]
export { specialPages }
